import React from 'react'
import Helmet from 'react-helmet'
import { Timeline, TimelineItem }  from 'vertical-timeline-component-for-react';

import Layout from '../components/layout'
// import Lightbox from 'react-images'
import Gallery from '../components/Gallery'

import form1 from '../assets/images/Murphy_patient_intake_form.pdf'
import form2 from '../assets/images/Murphy_confidentiality_fee_agreement.pdf'

class HomeIndex extends React.Component {

    render() {
        const siteTitle = "Diane Murphy L.C.S.W., Ph.D"
        const siteDescription = "Licensed Clinical Social Worker"

        return (
            <Layout>
                <Helmet>
                        <title>{siteTitle}</title>
                        <meta name="description" content={siteDescription} />
                </Helmet>

                <div id="main">

                    <section id="one">
                        <header className="major">
                            <h2>I believe therapy is one of the strongest tools available to help patients mature into their best selves.</h2>
                        </header>
                        <p>I am an experienced psychotherapist who works with adolescents and adults
                        in a private practice setting. My treatment approach is the culmination of
                        40+ years working in outpatient, inpatient, private practice, academic
                        and university settings. My clinical obligation is to provide a diagnostic
                        understanding and treatment for the vulnerabilities that limit a patient's
                        experience of well being.</p>

                        <p>There can be a mixture of psychological, biologic, familial, educational
                        and social components that enhance or complicate one's life. My work ideally
                        enables patients to experience more satisfaction due to making necessary changes
                        that were previously blocked prior to forming a trusting relationship with a well
                        trained, intuitive therapist.</p>

                        <ul className="actions">
                            <li><a href={form1} target="_blank" className="button">Patient Intake Form</a></li>
                            <li><a href={form2} target="_blank" className="button">Confidentiality Agreement, Fee Agreement</a></li>
                        </ul>

                        <p>After filling out the necessary forms, please email to <a href="mailto:dianemurphyphd@gmail.com">dianemurphyphd@gmail.com</a> or send to:</p>

                        <ul className="labeled-icons">
                                    <li>
                                        <h3 className="icon fa-home"><span className="label">Address</span></h3>
                                        <a href="https://goo.gl/maps/1BUgezauduW5DCZY6" target="_blank">
                                        153 Main St.<br />
                                        Suite G<br />
                                        Mount Kisco, NY 10549<br />
                                        United States
                                        </a>
                                    </li>
                                </ul>

                    </section>

                    <section id="two">
                        <h2>Services</h2>
                        <p>A core belief that its central to my work is that psychotherapy is a joint venture between the therapist and the patient. Patients share with me how they want a different present and future. Understanding the mix of our emotions, biology, culture, family and learning styles will enable change to occur.</p>
                        
                        <div className="row">

                            <div className="4u 12u$(small)">
                            <p className="list-header">Topics:</p>
                                <ul>
                                <li><span className="label">Anxiety</span></li>
                                <li><span className="label">Depression</span></li>
                                <li><span className="label">Self Esteem</span></li>
                                <li><span className="label">Relationships</span></li>
                                <li><span className="label">Trauma</span></li>
                                <li><span className="label">Life transitions</span></li>
                                </ul>
                            </div>

                            <div className="4u 12u$(small)">
                            <p className="list-header">Ages:</p>
                                <ul>
                                <li><span className="label">Adolescents</span></li>
                                <li><span className="label">Adults</span></li>
                                </ul>
                            </div>

                            <div className="4u 12u$(small)">
                            <p className="list-header">Treatment Modalities:</p>
                                <ul>
                                <li><span className="label">Individual Psychotherapy</span></li>
                                <li><span className="label">Family Psychotherapy</span></li>
                                <li><span className="label">Psychodynamic Orientation</span></li>
                                <li><span className="label">Interpersonal Exporation</span></li>
                                </ul>
                            </div>
                            
                        </div>
    
                    </section>


                    <section id="three">
                            <h2>Education</h2>

<Timeline lineColor={'#ddd'}>

  <TimelineItem
  key="001"
  dateText="1995"
  style={{ color: '#5774FF' }}
  dateInnerStyle={{ background: '#5774FF' }}
  >
    <h3>New York University</h3>
    <h4>Ehrenkranz School of Social Work: Ph.D, clinical tract</h4>
  </TimelineItem>

  <TimelineItem
  key="002"
  dateText="1977-1978"
  style={{ color: '#6D5AE6' }}
  dateInnerStyle={{ background: '#6D5AE6' }}
  >
    <h3>Menninger Foundation</h3>
    <h4>Post Masters Fellowship in Psychiatric Social Work, individual and family focus</h4>
  </TimelineItem>

  <TimelineItem
  key="003"
  dateText="1975-1977"
  style={{ color: '#FF7295' }}
  dateInnerStyle={{ background: '#FF7295' }}
  >
    <h3>University of Michigan</h3>
    <h4>MSW: School of Social Work, Clinical Track</h4>
  </TimelineItem>

  <TimelineItem
  key="004"
  dateText="1972"
  style={{ color: '#FF7369'}}
  dateInnerStyle={{ background: '#FF7369' }}
  className='lastitem'
  >
    <h3>Colgate Univerisity</h3>
    <h4>BA in Social Relations, Cum Laude</h4>
  </TimelineItem>

</Timeline>



                    </section>

                    <section id="four">
                        <h2>Get In Touch</h2>
                        <p>I provide psychotherapy sessions primarily by telehealth. I am in the office one time per week to work with patients in person.</p>
                        <div className="row">
                            <div className="8u 12u$(small)">
                            </div>
                            <div className="4u 12u$(small)">
                                <ul className="labeled-icons">
                                <li>
                                        <h3 className="icon fa-envelope-o"><span className="label">Email</span></h3>
                                        <a href="mailto:dianemurphyphd@gmail.com">dianemurphyphd@gmail.com</a>
                                    </li>
                                    <li>
                                        <h3 className="icon fa-mobile"><span className="label">Phone</span></h3>
                                        <a href="tel:914-232-4460">(914) 232 4460</a>
                                    </li>
                                    <li>
                                        <h3 className="icon fa-home"><span className="label">Address</span></h3>
                                        <a href="https://goo.gl/maps/1BUgezauduW5DCZY6" target="_blank">
                                        153 Main St.<br />
                                        Suite G<br />
                                        Mount Kisco, NY 10549<br />
                                        United States
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>
                    <section id="five">
                        <h2>Office Information</h2>
                        <p>On street, metered parking is available.<br />
                        Follow signs for municipal parking if hour parking is not sufficient.<br /><br />

                        I will provide you with the entrance code prior to your appointment.
</p>
                    </section>


                </div>

            </Layout>
        )
    }
}

export default HomeIndex
